.bg-sculpture {
    background-image: url('../../assets/images/sculpture.jpg');
}
.bg-drawings {
    background-image: url('../../assets/images/drawings.jpg');
}
.bg-sold {
    background-image: url('../../assets/images/sold.jpg');
}
.bg-available {
    background-image: url('../../assets/images/available.jpg');
}