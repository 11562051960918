h1, h4, p {
    text-align: left;
}
h1 {
    font-weight: bold;
    font-size: 2rem;
}
h4 {
    font-weight: bold;
    margin: 50px, 50px;
    font-size: 1.5rem;
}
p {
    font-size: 1.1rem;
}
